<!--  -->
<template>
  <div class="box" v-loading="loading">
    <div class="container" style="display: flex; flex-direction: column">
      <div class="headers" style="height: 60px">
        <div class="boxs">
          <div class="uulogo">
            <img class="image" :src="Logo" alt="" />
            <b class="fontsty" style="font-size: 24px; cursor: pointer">{{
              MaxTitle || ""
            }}</b>
          </div>
          <div class="subcontent">
            <div class="navcontent">
              <!-- <el-dropdown @command="handleRoles">
                <span class="el-dropdown-link" style="font-size: 14px">
                  <b>{{ UserInfo.ServiceName }}</b>
                  <b v-if="UserInfo.rolename">({{ UserInfo.rolename }})</b
                  ><i class="el-icon-sort"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, i) in Roles"
                    :key="i"
                    :command="item.useragentroleid"
                    >{{ item.ServiceProvidName }} <i>({{ item.rolename }})</i>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
              <el-dropdown @command="handleCommand2">
                <div class="el-dropdown-link">
                  <div class="userInfo" v-show="UserInfo.username">
                    <div class="usermsg ycang"> 
                      <b style="font-size: 12px"> {{ UserInfo.username }}({{ UserInfo.rolename }})</b>
                       <b style="font-size: 12px"
                        >{{ UserInfo.ServiceName }}</b
                      >
                    </div>
                  </div>
                  <i style="font-size: 20px" class="el-icon-caret-bottom"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="exit">注 销</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- <el-divider></el-divider> -->
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <main>
        <div class="container" style="height: 100%">
          <el-tabs
            tab-position="left"
            v-model="tabPosition"
            style="height: 100%"
          >
              <el-tab-pane
          v-for="(item, i) in Rolelist"
          :name="'_id' + item.useragentroleid" 
          :key="i"
          style="height: 100%;overflow-y: auto; "
        >
          <span slot="label"><b>{{ item.ServiceProvidName }}</b> ({{ item.rolename }})</span>
           <el-row 
           style="margin-top:10px">
             <el-col :span="11"
              v-for="(items, i) in item.UUSystems"
              :key="i"
               style="margin-bottom: 10px;margin-right:10px"
            >
              <button
                class="login_button"
                @click="toSysUrl(item.useragentroleid, items.SysUrl,items.SysCode)"
              >
                <span> {{ items.SysName }}</span>
             
              </button>
            </el-col>
          </el-row>
        </el-tab-pane>
          </el-tabs>
        </div> 
      </main>
    </div>
  </div>
</template>

<script>
import { GetCurUUSystems, GetUserRolesAndSystem } from "@/network/api";
import {
  SignOut,
  ChangeUserRole,
  GetUserRoles,
  GetUserInfo,
  querystatus,
  GetCurPlatformPersonalization,
  UpdateUserLastSysCode
} from "@/network/api";
export default {
  data() {
    return {
      list: [],
      Logo: "",
      MaxTitle: "",
      tabPosition: "",
      sysList: [],
      Roles: [],
      Rolelist: [],
      UserInfo: {},
      loading: true,
    };
  },

  components: {},

  computed: {},

  async mounted() {
    this.loading = await true;
    // await querystatus();
    // await this.$store.commit("addtoken", this.$route.query.uutoken);
    await GetCurPlatformPersonalization().then((res) => {
      if (res.code == 1) {
        var link =
          document.querySelector('link[rel*="icon"]') ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = res?.data?.Logo;
        this.Logo = res.data.Logo;
        this.MaxTitle = res.data.MaxTitle;
        if (this.MaxTitle) {
          document.title = this.MaxTitle + " - 系统列表";
        }
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: "error",
        });
      }
    }); 
    // await GetCurUUSystems().then((res) => {
    //   if (res.code == 1) {
    //     this.list = res.data.rows;
    //   } else if (res.code == 102 || res.code == 103) {
    //     this.$message({
    //       message: res.msg,
    //       center: true,
    //       type: "error",
    //       duration: 1000,
    //       onClose: () => {
    //         window.location.href = res.data;
    //       },
    //     });
    //   } else {
    //     this.$message({ message: res.msg, type: "error" });
    //   }
    // });
    await GetUserRolesAndSystem().then((res) => {
      if (res.code == 1) {
        this.Rolelist = res.data;
      } else if (res.code == 102 || res.code == 103) {
        this.$message({
          message: res.msg,
          center: true,
          type: "error",
          duration: 1000,
          onClose: () => {
            window.location.replace(res.data);
          },
        });
      } else {
        this.$message({ message: res.msg, type: "error" });
      }
    });

    await GetUserInfo().then((res) => {
      if (res.code == 1) {
        console.log("----------");
        this.UserInfo = res.data;
        let obj = this.Rolelist.filter(
          (item) =>
            item.roleid == res.data.roleid &&
            item.userid == res.data.userid &&
            item.serviceproviderid == res.data.serviceproviderid
        );
        console.log(obj, "----------");
        this.tabPosition =
          "_id" + (obj && obj[0] ? obj[0].useragentroleid : "");
        this.UserInfo = res.data;
      } else if (res.code == 102 || res.code == 103) {
        this.$message({
          message: res.msg,
          center: true,
          type: "error",
          duration: 1000,
          onClose: () => {
            window.location.replace(res.data);
          },
        });
      } else {
        this.$message({ message: res.msg, type: "error" });
      }
    });
    this.loading = await false;
  },

  methods: {
    toSysUrl(id, url,SysCode) {
      UpdateUserLastSysCode({LastLoginForuseragentroleid:id,LastLoginSysCode:SysCode}).then(()=>{
        return ChangeUserRole({ useragentroleid: id, SysCode });
      }).then((res) => {
        if (res.code == 1) {
          console.log(url);
          window.location.replace(url);
        } else if (res.code == 102 || res.code == 103) {
          this.$message({
            message: res.msg,
            center: true,
            type: "error",
            duration: 1000,
            onClose: () => {
              window.location.replace(res.data);
            },
          });
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },

    async handleCommand2(e) {
      switch (e) {
        case "exit":
          let res = await SignOut();
          // console.log(res);
          if (res.code == 1) {
            // this.$store.commit("cleartoken");
            window.location.replace(res.data);
            //  this.$router.replace("/Login2");
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang='less' scoped>
.box {
}
.boxs {
  display: flex;
  justify-content: space-between;
}
.clearfix {
  // padding: 50px;
  // width: 60%;
  // font-size: 22px;
  // margin: auto;
  text-align: center;
  // background-color: #1c87db;
  // color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.clearfix:hover {
  opacity: 0.8;
}
main {
  flex: 1;
  width: 100%;
}
.headers {
  text-align: center;
  padding: 0;
  margin-bottom: 10px;
}
.container {
  width: 100vw;
  max-width: 1100px;
  height: 100vh;
  margin: auto;
  position: relative;
}
.uulogo {
  width: 150px;
  padding-left: 50px;
  height: 60px;
  line-height: 60px;
      display: flex;
    align-items: center;
}
.image {
  width: 35px;
  height: 35px; 
  margin-right: 20px;
}
.kai {
}
/deep/ .el-divider--horizontal {
  margin: 0;
  margin-top: -2px;
}
.title2 {
  i {
    font-size: 30px;
    margin: 0 10px;
  }
  width: 200px;
}
.subcontent {
  flex: 1;
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
  // background-color: #23262e;
  // color: white;
}

.right {
  flex: 1;
  display: flex;
  > li {
    // border: 1px solid #ccc;
    padding: 0 20px;
    cursor: pointer;
    margin: -1px;
  }
}
.right-l {
  width: 30%;
  display: flex;
}
.right-l div {
  flex: 1;
  cursor: pointer;
}
.right-l div:hover {
  opacity: 0.6;
}
.navcontent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.screen {
  margin: 0 20px;
  i {
    font-size: 20px;
    font-weight: bold;
  }
}
.tip {
  display: flex;
  height: 100%;
  width: 50px;
  justify-content: center;
}
.time {
  width: 177px;
  font-size: 14px;
}
.el-icon-bell {
  width: 30px;
  height: 30px;
  font-size: 25px;
}
.msgnumber {
  width: 32px;
  height: 16px;
  text-align: center;
  border-radius: 15px;
  color: red;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  margin-top: 5px;
  margin-left: -12px;
}
.userInfo {
  display: flex;
}
.userimg {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userimg img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.usermsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.usermsg div {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}
.el-dropdown-link {
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  // color: rgb(83, 80, 80);
}
.el-icon-arrow-down {
  font-size: 12px;
}
div /deep/.el-dropdown {
  display: flex;
  align-items: center;
  // line-height: 60px;
  font-size: 30px;
}
.ellipsis {
  // 单行
  height: 60px;
  // overflow: hidden; /*超出宽度部分的隐藏*/
  // white-space: nowrap; /*文字不换行*/
  // text-overflow: ellipsis; /*超出则...代替*/
}
.divider {
  background: #ccc;
  width: 100%;
  height: 2px;
}
</style>